import { type StoreWithId } from "../../components/Sort/AvailabilitySelector/DialogStoreSelect";

const getBasicStoreData = (stores: StoreWithId[]): StoreWithId[] =>
  stores.map((store) => {
    // Removing these detailed attributes, not needed for many cases
    const {
      description,
      contact,
      googlePlaceId,
      linkButton,
      lastModified,
      ...storeWithoutDetails
    } = store;
    return storeWithoutDetails as unknown as StoreWithId;
  });

export { getBasicStoreData };
